import { defineStore } from "pinia";
import axios from "axios";
// import Cookies from "js-cookie";
import VueCookies from "vue-cookies";

export const useAuthStore = defineStore("auth", {
    state: () => ({
        isLoggedIn: false,
        userType: null,
    }),
    actions: {
        async checkLoginStatus() {
            // Get all cookies as a string

            // Log cookies
            // const userCookie = Cookies.get("user");
            // const adminCookie = Cookies.get("admin");
            // let gotCookie = this.getCookie("admin");
            // var user = { id: 1, name: "Journal", session: "25j_7Sl6xDq2Kc3ym0fmrSSk2xV2XkUkX" };
            // VueCookies.set("user322", user);

            // // let testCookie = this.$cookies.get("test");
            let logCookie = VueCookies.get("userLogged") === "true";

            try {
                const response = await axios.get("https://server.go-platform.com/user/isLoggedIn", {
                    withCredentials: true,
                });
                const userType = response.data.isLogged;
                this.userType = userType;
                this.isLoggedIn = logCookie ? true : false;
            } catch (error) {
                console.error("Error while checking login status:", error);
                this.isLoggedIn = false;
                this.userType = null;
            }
        },

        logout() {
            this.isLoggedIn = false;
            this.userType = null;
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("userType");
        },

        login(userType) {
            this.isLoggedIn = true;
            this.userType = userType;
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("userType", userType);
        },
        getCookie(name) {
            let cookies = document.cookie;
            console.log(cookies);
            const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
            if (match) return match[2]; // Return the cookie value
            return null; // Return null if cookie is not found
        },
    },
    persist: true, // Optional: persist the store state using Pinia’s plugin
});
