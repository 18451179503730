import { defineStore } from "pinia";
import axios from "axios";
import { useStore } from "./index";

export const useJobStore = defineStore("Job", {
    state: () => ({
        jobs: [],
    }),
    getters: {
        allJobs: (state) => state.jobs,
        // jobCategory:(state)=>{
        //     return (category)=>state.jobs.filter(job=>job.category === category)
        // },
        jobCategory: (state) => {
            return (category) => state.jobs.filter((job) => job.category === category);
        },
    },
    actions: {
        fetchJobs() {
            const Store = useStore();
            const headers = {
                "Content-Type": "application/json",
            };
            axios
                .get(`https://server.go-platform.com/jobs/data/${Store.company_name}`, {
                    headers,
                })
                .then((res) => {
                    this.jobs = res.data;
                    return this.jobs;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
    },
});
